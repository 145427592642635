<template>
    <v-row no-gutters>
      <v-col>
        <div id="product-list">
          <!-- app drawer -->
  
          <add-update
            v-model="isAddUpdateProductSidebarActive"
            :product-type="productType"
            :product-item="productItem"
            @refetch-data="fetchProducts"
            @save-product-event="saveProductEvent"
          ></add-update>
  
          <v-snackbars
            :objects.sync="snackbars"
            width="500"
            top
            right
            transition="slide-y-transition"
          >
            <template v-slot:action="{ close }">
              <v-btn
                icon
                small
                @click="close()"
              >
                <v-icon small>
                  {{ icons.mdiClose }}
                </v-icon>
              </v-btn>
            </template>
          </v-snackbars>
  
          <!-- list filters -->
  
          <v-card
            tile
            :flat="$vuetify.breakpoint.mdAndDown"
            :class="[{ 'rounded-t-lg': !$vuetify.breakpoint.mdAndDown }, { 'rounded-l-xl': userData.role === 'SUPER' }]"
          >
            <v-toolbar
              :color="$vuetify.breakpoint.mdAndDown ? '#3b355a' : 'transparent'"
              flat
              :height="$vuetify.breakpoint.smAndDown ? 57 : 87"
            >
              <v-toolbar-title class="text-h6 font-weight-medium">
                <v-icon
                  large
                  left
                >
                  {{ icons.mdiShape }}
                </v-icon>
                Ürün Kategorileri
              </v-toolbar-title>
  
              <v-spacer />
  
              <!-- <v-btn plain text small>
                <v-icon left>
                  {{ icons.mdiFilter }}
                </v-icon>
                Filitre
              </v-btn> -->
              <v-btn
                class="text-capitalize ms-3 rounded-l-pill rounded-r-0 mr-n4 bg-gradient-primary white--text"
                depressed
                x-large
                @click="addProduct()"
              >
                <span>Yeni Ekle</span>
              </v-btn>
            </v-toolbar>
  
            <!-- table -->
            <v-data-table
              :headers="tableColumns"
              :items="productListTable"
              :items-per-page="itemsPerPage"
              fixed-header
              :height="dataTableHeight"
              :loading="loading"
              :footer-props="{
                showFirstLastPage: true,
  
              }"
            >
              <!-- Text -->
              <template v-slot:body.prepend>
                <tr style="zoom: 0.8">
                  <td class="pa-0">
                    <v-text-field
                      disabled
                      filled
                      class="rounded-0 elevation-0"
                      hide-details
                    ></v-text-field>
                  </td>
  
                <!--   <td class="pa-0">
                    <v-select
                      v-model="tipSearch"
                      :prepend-inner-icon="icons.mdiMagnify"
                      persistent-placeholder
                      :items="[
                        { text: 'Hepsi', value: '' },
                        { text: 'Mamul', value: 1 },
                        { text: 'Sarf', value: 2 },
                      ]"
                      placeholder="Ara"
                      filled
                      class="rounded-0 elevation-0"
                      hide-details
                      attach
                      :menu-props="{ bottom: true, offsetY: true }"
                    ></v-select>
                  </td> -->
                  <td class="pa-0">
                    <v-text-field
                      v-model="kategoriSearch"
                      :prepend-inner-icon="icons.mdiMagnify"
                      persistent-placeholder
                      placeholder="Ara"
                      filled
                      class="rounded-0 elevation-0"
                      hide-details
                    ></v-text-field>
                  </td>
  
                  <td class="pa-0">
                    <v-text-field
                      filled
                      disabled
                      class="rounded-0 elevation-0"
                      hide-details
                    ></v-text-field>
                  </td>
                </tr>
              </template>
              <template #[`item.ust`]="{ item }">
                <v-chip
                  :color="item.ust === '1' ? 'success' : 'warning'"
                  label
                  outlined
                  small
                  style="width: 100%; justify-content: center"
                >
                  {{ item.ust === '1' ? 'Mamül' : 'Sarf' }}
                </v-chip>
              </template>
  
              <template #[`item.baslik`]="{ item }">
                <div class="text-body-2 white--text">
                  {{ item.baslik }}
                </div>
              </template>
  
              <template #[`item.actions`]="{ item }">
                <v-btn
                  depressed
                  color="nightDark"
                  class="text-capitalize"
                  small
                  @click="editProduct(item)"
                >
                  <v-icon
                    small
                    left
                  >
                    {{ icons.mdiSquareEditOutline }}
                  </v-icon>
                  Düzenle
                </v-btn>
                <v-btn
                  plain
                  small
                  class="text-capitalize"
                  @click="deleteProduct(item)"
                >
                  <v-icon left>
                    {{ icons.mdiDelete }}
                  </v-icon>
                  Sil
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </template>
  
  <script>
  import store from '@/store'
  import {
  mdiCamera,
  mdiCameraOff,
  mdiClose,
  mdiDelete,
  mdiEye,
  mdiFilter,
  mdiFilterOff,
  mdiMagnify,
  mdiPlusThick,
  mdiShape,
  mdiSquareEditOutline,
  } from '@mdi/js'
  import {
  computed, onMounted, ref, watch,
  } from '@vue/composition-api'
  import VSnackbars from 'v-snackbars'
  import Vue from 'vue'
  import addUpdate from './AddUpdate.vue'
  
  const userData = JSON.parse(localStorage.getItem('userData'))
  
  export default {
    components: {
      addUpdate,
      VSnackbars,
    },
    setup() {
      const snackbars = ref([])
      const isAddUpdateProductSidebarActive = ref(false)
      const productListTable = ref([])
      const productType = ref('add')
      const productItem = ref({})
      const kategoriSearch = ref('')
      const tipSearch = ref('')
      const tableColumns = computed(() => [
        {
          text: 'Sıra',
          value: 'sira',
          width: '90px',
          sortable: false,
        },
      /*   {
          text: 'Tip',
          value: 'ust',
          width: '120px',
          filter: f => `${f}`.toLowerCase().includes(tipSearch.value),
        }, */
  
        {
          text: 'Kategori Adı',
          value: 'kategori',
          filter: f => `${f}`.toLowerCase().includes(kategoriSearch.value.toLowerCase()),
        },
  
        {
          text: '',
          value: 'actions',
          align: 'right',
          width: '225px',
          sortable: false,
        },
      ])
  
      const idFilter = ref('')
      const totalProductListTable = ref(0)
      const loading = ref(false)
      const options = ref({
        sortBy: ['id'],
        sortDesc: [true],
        itemsPerPage: 1000,
      })
      const productTotalLocal = ref([])
  
      const deleteProduct = item => {
        Vue.swal({
          title: 'Emin misin?',
          text: 'Silme işlemini onaylayın',
          icon: 'question',
          background: '#FF4C51',
          showCloseButton: false,
          showCancelButton: true,
          width: '300px',
          confirmButtonText: 'Onaylıyorum',
          cancelButtonColor: '#FF4C51',
          confirmButtonColor: '#cc3d41',
        }).then(result => {
          if (result.isConfirmed) {
            loading.value = true
            store
              .dispatch('postMethod', {
                method: 'deleteCategory',
                id: item.id,
              })
              .then(() => {
                snackbars.value.push({
                  message: 'Silindi',
                  color: 'error',
                  timeout: 5000,
                })
  
                fetchProducts()
              })
              .catch(e => {
                Vue.swal({
                  title: 'Hata',
                  text: e.message,
                  width: '350px',
                  icon: 'error',
                  background: '#FF4C51',
                  showCloseButton: false,
                  showConfirmButton: true,
                  confirmButtonColor: '#cc3d41',
                })
              })
          }
        })
      }
  
      const saveProductEvent = val => {
        snackbars.value.push(val)
      }
  
      const editProduct = item => {
        productItem.value = item
        productType.value = 'edit'
        isAddUpdateProductSidebarActive.value = true
      }
  
      const addProduct = () => {
        productItem.value = {}
        productType.value = 'add'
        isAddUpdateProductSidebarActive.value = true
      }
  
      const fetchProducts = () => {
        const query = {
          options: options.value,
          't.id': idFilter.value,
        }
        debugger
        const method = 'adminCategoryList'
        store
          .dispatch('fetchMethod', { query, method })
          .then(response => {
            productListTable.value = response.response.result
            totalProductListTable.value = Number(response.response.pagination.totalRec)
            loading.value = false
          })
          .catch(e => {
            loading.value = false
            Vue.swal({
              title: 'Hata',
              text: e.message,
              width: '350px',
              icon: 'error',
              background: '#FF4C51',
              showCloseButton: false,
              showConfirmButton: true,
              confirmButtonColor: '#cc3d41',
            })
          })
      }
  
      onMounted(() => {
        fetchProducts()
      })
      watch([idFilter, options], () => {
        loading.value = true
        fetchProducts()
      })
  
      return {
        tipSearch,
        kategoriSearch,
        productItem,
        editProduct,
        addProduct,
        productType,
        productListTable,
        tableColumns,
        idFilter,
        totalProductListTable,
        loading,
        options,
        productTotalLocal,
        isAddUpdateProductSidebarActive,
        fetchProducts,
        deleteProduct,
        snackbars,
        saveProductEvent,
        userData,
  
        // icons
        icons: {
          mdiCamera,
          mdiDelete,
          mdiCameraOff,
          mdiShape,
          mdiEye,
          mdiFilter,
          mdiFilterOff,
          mdiPlusThick,
          mdiSquareEditOutline,
          mdiClose,
          mdiMagnify,
        },
      }
    },
    data() {
      return {
        itemsPerPage: null,
        panel: null,
        date: null,
        modal: false,
        filterStatus: false,
        dataTableHeight: '',
        height: {
          system: 0,
          top: this.$vuetify.breakpoint.smAndDown ? 48 : 87,
          footer: this.$vuetify.breakpoint.smAndDown ? 47 : 47,
        },
      }
    },
  
    watch: {
      panel(val) {
        if (val === 0) {
          setTimeout(() => {
            this.height.top = 250
            this.onResize()
          }, 300)
        } else {
          this.height.top = 48
          this.onResize()
        }
      },
    },
  
    mounted() {
      this.$nextTick(() => {
        this.onResize()
        window.addEventListener('resize', this.onResize)
      })
    },
  
    beforeDestroy() {
      window.removeEventListener('resize', this.onResize)
    },
    methods: {
      onResize() {
        this.itemsPerPage = Math.ceil(window.innerHeight / 100) + 4
        if (userData.role === 'SUPER') {
          this.dataTableHeight = window.innerHeight - 128
        } else {
          this.dataTableHeight = window.innerHeight - 90 - this.height.top - this.height.footer - 1
        }
  
        const r = document.querySelector(':root')
        r.style.setProperty('--height', `${(this.dataTableHeight - 100) / this.itemsPerPage}px`)
      },
    },
  }
  </script>
  
  <style lang="scss">
  :root {
    --height: 30px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    height: var(--height);
  }
  .v-data-footer__select {
    display: none !important;
  }
  </style>
  